import { TranslationKey } from '~/lib/data-contract';
import { Frame } from '~/shared/utils';
import { Product } from '~/templates/blocks/components/M140ProductsList/model';
import { getValueTranslation } from './getColumnTranslation';
import { getProductHoverImage, getProductImage } from './getProductImage';
import { getProductVariantColors } from './getProductVariantColors';
import { getDimensionsText, getFallHeightText, getSpaceRequired } from './GetSpaceRequired';
import { getPriceText } from './getPriceText';

type MapProductToProductCardParams = {
    product: Product;
    productPageUrl: string;
    frame: Frame;
    translate: (key: TranslationKey, fallback?: string) => string;
};

export const mapProductToBasicProps = ({
    product,
    productPageUrl,
    frame,
    translate,
}: MapProductToProductCardParams) => {
    const name = product.displayName ?? '';
    const colors = getProductVariantColors(product);
    const image = getProductImage(product);
    const hoverImage = getProductHoverImage(product);
    const locale = `${frame?.culture}-${frame?.market?.market?.toUpperCase()}`;
    const priceText = getPriceText(product, frame, locale);

    const spaceRequired = getSpaceRequired(product, locale);
    const dimensionsText = getDimensionsText(product);
    const fallHeightText = getFallHeightText(product, locale);

    const userCapacityText = product?.data?.UserCapacity?.value
        ? getValueTranslation(translate, 'UserCapacity', `${product?.data?.UserCapacity?.value}`)
        : undefined;
    return {
        title: name,
        productId: product.productId ?? '',
        colors,
        url: `${productPageUrl}/${product?.productId?.toLowerCase()}`,
        imageUrl: image,
        ageGroup: product?.data?.AgeGroup?.value,
        userCapacity: userCapacityText,
        spaceRequired: spaceRequired,
        dimensionsText: dimensionsText,
        price: priceText ? getValueTranslation(translate, 'Price', `${priceText}`) : undefined,
        hoverImageUrl: hoverImage,
        fallHeightText: fallHeightText,
    };
};
