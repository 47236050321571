import { TranslationKey } from '~/lib/data-contract';

export const getColumnTitleTranslation = (
    translate: (key: TranslationKey, fallback?: string) => string,
    attribute: string,
    translationsGroup = 'Kompan.Commerce'
) => {
    return translate(
        `${translationsGroup}.Columns${attribute}Title` as TranslationKey,
        `${attribute}`
    ).replace('[x]', `${attribute}`);
};

export const getValueTranslation = (
    translate: (key: TranslationKey, fallback?: string) => string,
    attribute: string,
    value: number | string | boolean,
    translationsGroup = 'Kompan.Commerce'
) => {
    return translate(
        `${translationsGroup}.Columns${attribute}Value` as TranslationKey,
        `${value}`
    ).replace('[x]', `${value}`);
};

export const getColumnTooltipTranslation = (
    translate: (key: TranslationKey, fallback?: string) => string,
    attribute: string,
    translationsGroup = 'Kompan.Commerce'
) => {
    return translate(`${translationsGroup}.Columns${attribute}Tooltip` as TranslationKey);
};
