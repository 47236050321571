import { Product } from '~/templates/blocks/components/M140ProductsList/model';
import { SuperText } from '~/shared/components/SuperText/SuperText';
import { formatDistance } from '~/shared/utils/format/format-distance';

export const getMeasurementUnitFormat = (
    fieldValue: number,
    spaceMeasurementUnit: string = 'cm',
) => {
    if (spaceMeasurementUnit === 'cm' || spaceMeasurementUnit === 'm²') {
        return <SuperText value={`${fieldValue} m`} sup="2" />;
    } else {
        return <SuperText value={`${fieldValue} ft`} sup="2" />;
    }
};

export const getSpaceRequired = (product: Product, locale: string) => {
    // @ts-expect-error it's a dynamic key
    const fieldValue = product?.data?.[`SafetyZoneArea_${locale}`]?.value;

    if (fieldValue === 0 || typeof fieldValue === 'undefined') return undefined;

    return getMeasurementUnitFormat(fieldValue, product?.data?.spaceMeasurementUnit?.value);
};

export const getDimensionsText = (product: Product) => {
    const { SafetyZoneAreaLength, SafetyZoneAreaWidth, spaceMeasurementUnit } =
        product?.data || null;

    const formattedLength = parseFloat(SafetyZoneAreaLength?.value ?? '0');
    const formattedWidth = parseFloat(SafetyZoneAreaWidth?.value ?? '0');

    if (formattedLength === 0 && formattedWidth === 0) return;

    // If we're formatting as feet and inches, length param should include it too.
    const lengthText =
        spaceMeasurementUnit?.value == 'inch'
            ? formatDistance(formattedLength, spaceMeasurementUnit?.value)
            : formattedLength;
    return (
        <>
            <span>{lengthText}</span>
            <span> x </span>
            <span>{formatDistance(formattedWidth, spaceMeasurementUnit?.value)}</span>
        </>
    );
};

const formatFallHeight = (value?: string, unit?: string) => {
    if (!value || !unit) {
        return;
    }

    return formatDistance(parseFloat(value), unit);
};

export const getFallHeightText = (product: Product, locale: string) => {
    return formatFallHeight(
        // @ts-expect-error it's a dynamic key
        product?.data?.[`MaxFallHeight_${locale}`]?.value,
        product?.data?.MaxFallHeightUnit?.value,
    );
};
