export const SuperText = ({ value, sup }: { value: string; sup: string }) => {
    return (
        <>
            {value}
            <sup
                style={{
                    verticalAlign: 'super',
                    fontSize: 'xx-small',
                    lineHeight: 0,
                }}
            >
                {sup}
            </sup>
        </>
    );
};
