import {
    ContentSearchRequestResponseType,
    dataFieldValueContainsFilter,
    facetButtons,
    FacetsBodyType,
    hiddenFacets,
    noEmptyDataFieldFilter,
    ProductCategoryQueryResponseType,
    ProductSearchRequestResponseType,
    productStoreUrlHelper,
    RelewiseProductSearchFacetTypes,
    SearchRequestCollectionResponseType,
    SearchResultsType,
    sortOptions,
    staticFilters,
    FacetIdentifierType,
    PredefinedFilters,
} from '$templates/blocks/components/M140ProductsList';
import { useEffect, useMemo } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useSearchState } from '~/features/navigation/components/N25SearchBar';
import { fetcher } from '~/lib/fetcher';
import { publicRuntimeConfig } from '~/shared/utils/public-variables';
import { usePage } from '~/templates/pages';
import { DEFAULT_ITEMS_PER_PAGE } from '../../constants';
import { useProductsStore, useProductsStoreUrlState } from '../hooks';
import { getRelewiseContentSearchQuery } from '../lib/getRelewiseContentSearchQuery';
import { useRelewiseTracking } from '~/shared/hooks/useRelewiseTracking/useRelewiseTracking';
import { User } from '@relewise/client';
import { useFrame } from '~/shared/utils';
import { getSort, hydrateFacetsWithSelectedFacets, mapResponseFacets } from './utils';
import { getRelewiseProductQueryKey } from './utils/getRelewiseProductQueryKey';
import { getRelewiseCategoriesQueryKey } from './utils/getRelewiseCategoriesQueryKey';
import { useModule } from '~/templates/blocks/hooks/useModule';
import { M140ProductListModule } from '~/lib/data-contract';

export const getProductProperties = (lang: string | undefined = 'en-INT') => {
    const [locale, market] = lang.split('-');
    const langKey = `${locale.toLowerCase()}-${market.toUpperCase()}`;
    return {
        displayName: true,
        pricing: true,
        description: false,
        categoryPaths: true,
        assortments: false,
        disabled: true,
        allVariants: true,
        brand: false,
        category: false,
        dataKeys: [
            'UserCapacity',
            'IsInclusive',
            'MaxFallHeightValue',
            'MaxFallHeightUnit',
            'LengthValue',
            'LengthUnit',
            'WidthValue',
            'WidthUnit',
            'HeightValue',
            'HeightUnit',
            'SafetyZoneAreaLength',
            'SafetyZoneAreaWidth',
            'spaceMeasurementUnit',
            'AgeGroup',
            'ShowPriceOnKompan',
            'listPrice',
            'Activities',
            `AgeGroup_from_${langKey}`,
            // `AgeGroup_plp_from_${langKey}`,
            `MaxFallHeight_${langKey}`,
            `SafetyZoneArea_${langKey}`,
            'ProductPhotos',
        ],
    };
};

export const productVariantProperties = {
    displayName: false,
    pricing: true,
    dataKeys: ['MainColor', 'MainColorHexCode', 'IsMainVariant', 'ImageCAD1', 'ImageCAD2'],
};

export const generateLockedFacets = (filters?: PredefinedFilters) => {
    const facets: FacetsBodyType[] = [];

    if (filters?.playCapacityFrom || filters?.playCapacityTo) {
        facets.push({
            $type: RelewiseProductSearchFacetTypes.PRODUCT_DATA_DOUBLE_RANGE,
            field: 'Data',
            key: 'UserCapacity',
            dataSelectionStrategy: 'Product',
            collectionFilterType: 'Or',
            selected: {
                lowerBoundInclusive: filters?.playCapacityFrom ?? 0,
                upperBoundInclusive: filters?.playCapacityTo ?? Number.MAX_SAFE_INTEGER,
            },
        });
    }

    if (filters?.keyUserGroupFromMin || filters?.keyUserGroupFromMax) {
        facets.push({
            $type: RelewiseProductSearchFacetTypes.PRODUCT_DATA_DOUBLE_RANGE,
            field: 'Data',
            key: 'KeyUserGroupFrom',
            dataSelectionStrategy: 'Product',
            collectionFilterType: 'Or',
            selected: {
                lowerBoundInclusive: filters?.keyUserGroupFromMin ?? 0,
                upperBoundInclusive: filters?.keyUserGroupFromMax ?? Number.MAX_SAFE_INTEGER,
            },
        });
    }

    if (filters?.keyUserGroupToMin || filters?.keyUserGroupToMax) {
        facets.push({
            $type: RelewiseProductSearchFacetTypes.PRODUCT_DATA_DOUBLE_RANGE,
            field: 'Data',
            key: 'KeyUserGroupTo',
            dataSelectionStrategy: 'Product',
            collectionFilterType: 'Or',
            selected: {
                lowerBoundInclusive: filters?.keyUserGroupToMin ?? 0,
                upperBoundInclusive: filters?.keyUserGroupToMax ?? Number.MAX_SAFE_INTEGER,
            },
        });
    }

    if (filters?.inclusive) {
        facets.push({
            $type: RelewiseProductSearchFacetTypes.PRODUCT_DATA_STRING,
            field: 'Data',
            key: 'IsInclusive',
            dataSelectionStrategy: 'Product',
            collectionFilterType: 'Or',
            selected: ['True'],
        });
    }

    if (filters?.quickSupply) {
        facets.push({
            $type: RelewiseProductSearchFacetTypes.PRODUCT_DATA_STRING,
            field: 'Data',
            key: 'QuickSupply',
            dataSelectionStrategy: 'Product',
            collectionFilterType: 'Or',
            selected: ['True'],
        });
    }

    return facets;
};

export const fetchCategories = async (lang: string) => {
    const { RELEWISE_API_URL, RELEWISE_ENVIRONMENT_ID, RELEWISE_SEARCH_API_KEY } =
        publicRuntimeConfig();
    const body = {
        filters: {
            Items: [],
        },
        language: {
            value: lang,
        },
        NumberOfResults: 2147483647,
        IncludeChildCategoriesToDepth: 5, // Max level of 5
        IncludeParentCategoriesToDepth: 5, // Max level of 5
        SkipNumberOfResults: 0,
        ReturnTotalNumberOfResults: false,
        IncludeDisabledCategories: false,
    };
    const response = await fetcher<ProductCategoryQueryResponseType>(
        `${RELEWISE_API_URL}/${RELEWISE_ENVIRONMENT_ID}/v1/ProductCategoryQuery`,
        {},
        { Authorization: `APIKey ${RELEWISE_SEARCH_API_KEY}` },
        'POST',
        JSON.stringify(body),
    );
    const data = await response.json();
    return data;
};

export const getProductResponse = (
    response?: SearchRequestCollectionResponseType,
): ProductSearchRequestResponseType | undefined => {
    return response?.responses?.find((res) => res.$type == SearchResultsType.PRODUCT_SEARCH) as
        | ProductSearchRequestResponseType
        | undefined;
};

export const getContentResponse = (
    response?: SearchRequestCollectionResponseType,
): ContentSearchRequestResponseType | undefined => {
    return response?.responses?.find((res) => res.$type == SearchResultsType.CONTENT_SEARCH) as
        | ContentSearchRequestResponseType
        | undefined;
};

/**
 * Helper method for requesting relewise data information.
 * Returns headers, status and data
 */

export const useRelewiseProductsAPI = (
    sidebarFilter?: FacetIdentifierType,
    useUrlState = false,
    predefinedFacets?: PredefinedFilters,
) => {
    const { element: m140Module } = useModule<M140ProductListModule>();
    const lockedFacets = useMemo(() => generateLockedFacets(predefinedFacets), [predefinedFacets]);
    const {
        setPagination,
        setProducts,
        appendProducts,
        prependProducts,
        setTotalProducts,
        setIsSearching,
        setFacets,
        setSearchSidebarFacet,
        products,
        pagination,
        selectedFacets,
        selectedSort,
        selectedFacetsSort,
        selectedCategories,
        activeCategories,
        setSortOptions,
        setFacetButtons,
        setAvailableCategories,
        setHiddenFacets,
        setContentResults,
    } = useProductsStore();

    const { query } = useSearchState();
    const { setUrlFromState } = useProductsStoreUrlState();
    const { getFacetQueryKey } = productStoreUrlHelper();
    const { market = 'int', culture = 'en' } = usePage();
    const { data: frame } = useFrame();
    const localeString = `${culture}-${market}`;
    const queryClient = useQueryClient();
    const { getUserObject } = useRelewiseTracking();

    useEffect(() => {
        setPagination({
            ...pagination,
            page: 0,
        });
    }, [query]);

    useEffect(() => {
        queryClient
            .fetchQuery(getRelewiseCategoriesQueryKey({ locale: localeString }), () =>
                fetchCategories(localeString),
            )
            .then((response) => {
                const l1Categories = response?.categories?.filter((category) => {
                    return category?.parentCategories?.length == 0 && category.categoryId.length;
                });
                const l1CategoryIds = l1Categories?.map((cat) => cat.categoryId);
                const l2Categories = response?.categories?.filter((category) => {
                    return (
                        category.categoryId.length &&
                        l1CategoryIds.includes(category.parentCategories?.[0]?.categoryId)
                    );
                });
                setAvailableCategories({
                    l1: l1Categories,
                    l2: l2Categories,
                    allCategories: response.categories || [],
                });
            });
    }, [localeString]);

    useEffect(() => {
        setSortOptions(sortOptions);
        setFacetButtons(facetButtons);
        const cmsLockedFacets = lockedFacets.map((x) => x.key ?? x.field);
        const facetsToHide = [...hiddenFacets, ...cmsLockedFacets];
        setHiddenFacets(facetsToHide);

        // Extra guard for initial content. This only fixes the issue if initial SSR does not load the previous pages.
        if (pagination.type == 'continuous' && pagination.page > 0) {
            fetchPreviousPages();
        }
    }, []);

    // Different sorting not yet implemented
    // const sortReactQueryKey = selectedSort?.attribute
    //     ? `${selectedSort.attribute}-${selectedSort.sort}`
    //     : undefined;

    const fetchPreviousPages = async () => {
        if (products.length > pagination.itemsPerPage) {
            return;
        }
        const user = getUserObject();
        const response = await queryClient.fetchQuery(
            getRelewiseProductQueryKey({
                locale: localeString,
                page: 0,
                perPage: pagination.page * pagination.itemsPerPage,
                query: query,
                selectedFacets: selectedFacets,
                facetKey: facetKey,
                activeCategories: [...(activeCategories ?? []), ...selectedCategories],
                lockedFacets: lockedFacets,
                sortKey: selectedFacetsSort.sortKey
                    ? `${selectedFacetsSort.sortKey}-${selectedFacetsSort.sortOrder}-${pagination.page}-${pagination.itemsPerPage}`
                    : undefined,
            }),
            () =>
                fetchProducts({
                    skip: 0,
                    take: pagination.page * pagination.itemsPerPage,
                    filters: getFilters(),
                    filterCollections: [],
                    sort: getSort(selectedFacetsSort, selectedSort),
                    query: query,
                    lang: localeString,
                    user,
                    currency: `${frame?.currency}-${culture.toUpperCase()}-${market}`,
                }),
        );
        const productResponse = getProductResponse(response);

        if (productResponse?.results) {
            prependProducts(productResponse?.results || []);
        }
    };

    const getFilters = () => {
        const shouldIncludeWebGeneralTags = !!m140Module?.webGeneralTagsOptions?.length;
        const filters = hydrateFacetsWithSelectedFacets(
            staticFilters({ market, culture, includeWebGeneralTags: shouldIncludeWebGeneralTags }),
            selectedFacets,
        );
        const categoryFilterIndex = filters.findIndex((filter) => filter.field == 'Category');
        if (selectedCategories.length) {
            filters[categoryFilterIndex]['selected'] = [...selectedCategories];
        } else if (activeCategories?.length) {
            filters[categoryFilterIndex]['selected'] = activeCategories;
        }

        // Filter out all regular facets that are present in the locked facets.
        lockedFacets.forEach((facet) => {
            const filterIndex = filters.findIndex(({ key, field }) => {
                return key === facet.key && field === facet.field;
            });

            if (filterIndex !== -1) {
                filters.splice(filterIndex, 1);
            }
        });

        filters.push(...lockedFacets);

        return filters;
    };

    const requestWithBody = async (pageOverride?: number) => {
        const page = pageOverride ?? pagination.page;
        const filters = getFilters();
        const sort = getSort(selectedFacetsSort, selectedSort);
        const user = getUserObject();

        return fetchProducts({
            skip: pagination.itemsPerPage * page,
            take: pagination.itemsPerPage,
            filters: filters,
            filterCollections: [],
            sort: sort,
            query: query,
            lang: localeString,
            user,
            currency: `${frame?.currency}-${culture.toUpperCase()}-${market}`,
        });
    };

    const prefetchNextPage = async () => {
        await queryClient.prefetchQuery(
            getRelewiseProductQueryKey({
                locale: localeString,
                page: pagination.page + 1,
                perPage: pagination.itemsPerPage,
                query: query,
                selectedFacets: selectedFacets,
                facetKey: facetKey,
                activeCategories: [...(activeCategories ?? []), ...selectedCategories],
                lockedFacets: lockedFacets,
                sortKey: selectedFacetsSort.sortKey
                    ? `${selectedFacetsSort.sortKey}-${selectedFacetsSort.sortOrder}-${pagination.page}-${pagination.itemsPerPage}`
                    : undefined,
            }),
            () => requestWithBody(pagination.page + 1),
        );
    };

    const facetKey = getFacetQueryKey(selectedFacets);

    const { data, isPreviousData, isLoading } = useQuery<SearchRequestCollectionResponseType>(
        getRelewiseProductQueryKey({
            locale: localeString,
            page: pagination.page,
            perPage: pagination.itemsPerPage,
            query: query,
            selectedFacets: selectedFacets,
            facetKey: facetKey,
            activeCategories: [...(activeCategories ?? []), ...selectedCategories],
            lockedFacets: lockedFacets,
            sortKey: selectedFacetsSort.sortKey
                ? `${selectedFacetsSort.sortKey}-${selectedFacetsSort.sortOrder}-${pagination.page}-${pagination.itemsPerPage}`
                : undefined,
        }),
        () => requestWithBody(),
        {
            keepPreviousData: true,
            cacheTime: 1000 * 60 * 30, // 30 minutes cache time
            staleTime: 1000 * 60 * 30, // 30 minutes cache time
            retryOnMount: false,
        },
    );

    useEffect(() => {
        const productData = getProductResponse(data);
        const dynamicItemsPerPage =
            pagination.itemsPerPage != 0 ? pagination.itemsPerPage : DEFAULT_ITEMS_PER_PAGE;
        const hasResults = productData?.results && productData?.results.length > 0;
        const hits = hasResults ? productData?.hits || 0 : 0;
        const totalPages = hits > 0 ? Math.ceil(hits / dynamicItemsPerPage) : 0;
        setTotalProducts(hits);

        if (pagination.type == 'continuous' && pagination.page > 0) {
            const firstId = productData?.results?.[0]?.productId;
            // Only append if we don't already have the product in the list.
            if (!products?.find((product) => product.productId == firstId)) {
                appendProducts(productData?.results ?? []);
            }
        } else {
            setProducts(productData?.results ?? []);
        }

        setPagination({
            ...pagination,
            itemsPerPage: dynamicItemsPerPage,
            totalPages: totalPages,
        });

        const facets = mapResponseFacets(
            productData?.facets?.items.filter(
                (facet) =>
                    !sidebarFilter ||
                    (sidebarFilter?.key?.toLocaleLowerCase() !== facet.key?.toLocaleLowerCase() &&
                        sidebarFilter?.key?.toLocaleLowerCase() !==
                            facet.field.toLocaleLowerCase()),
            ) ?? [],
        );
        setFacets(facets);

        const searchSideBarFilter = mapResponseFacets(
            productData?.facets?.items.filter(
                (facet) =>
                    sidebarFilter &&
                    (sidebarFilter?.key?.toLocaleLowerCase() === facet.key?.toLocaleLowerCase() ||
                        sidebarFilter?.key?.toLocaleLowerCase() ===
                            facet.field.toLocaleLowerCase()),
            ) ?? [],
        );

        setSearchSidebarFacet(searchSideBarFilter.length >= 1 ? searchSideBarFilter[0] : undefined);

        if (totalPages > pagination.page + 1) {
            prefetchNextPage();
        }
        const contentResults = getContentResponse(data);
        if (contentResults) {
            setContentResults(contentResults.results);
        }

        setIsSearching(isPreviousData || isLoading);
    }, [data]);

    useEffect(() => {
        if (useUrlState) {
            setUrlFromState();
        }
    }, [pagination.page, selectedCategories, selectedFacets, selectedFacetsSort]);

    useEffect(() => {
        setIsSearching(isPreviousData || isLoading);
    }, [isPreviousData, isLoading]);
};

type getPersonalizedFilterProps = {
    key?: string;
    selected?: {
        upperBoundExclusive: number;
    }[];
};

function getPersonalizedFilter(filters: getPersonalizedFilterProps[], lang: string) {
    const result = filters?.find((filter) => {
        return filter?.key?.startsWith('SafetyZoneAreaWidth_');
    });

    const widthValue = result?.selected && result?.selected[0]?.upperBoundExclusive;
    const lengthValue = result?.selected && result?.selected[1]?.upperBoundExclusive;

    if (!widthValue || !lengthValue) return null;
    return {
        $type: 'Relewise.Client.Requests.Filters.OrFilter, Relewise.Client',
        filters: [
            {
                $type: 'Relewise.Client.Requests.Filters.AndFilter, Relewise.Client',
                filters: [
                    {
                        $type: 'Relewise.Client.Requests.Filters.ProductDataFilter, Relewise.Client',
                        key: `SafetyZoneAreaWidth_${lang}`,
                        filterOutIfKeyIsNotFound: true,
                        mustMatchAllConditions: true,
                        conditions: {
                            items: [
                                {
                                    $type: 'Relewise.Client.Requests.Conditions.LessThanCondition, Relewise.Client',
                                    value: widthValue,
                                    negated: false,
                                },
                            ],
                        },
                        negated: false,
                    },
                    {
                        $type: 'Relewise.Client.Requests.Filters.ProductDataFilter, Relewise.Client',
                        key: `SafetyZoneAreaLength_${lang}`,
                        filterOutIfKeyIsNotFound: true,
                        mustMatchAllConditions: true,
                        conditions: {
                            items: [
                                {
                                    $type: 'Relewise.Client.Requests.Conditions.LessThanCondition, Relewise.Client',
                                    value: lengthValue,
                                    negated: false,
                                },
                            ],
                        },
                        negated: false,
                    },
                ],
                negated: false,
            },
            {
                $type: 'Relewise.Client.Requests.Filters.AndFilter, Relewise.Client',
                filters: [
                    {
                        $type: 'Relewise.Client.Requests.Filters.ProductDataFilter, Relewise.Client',
                        key: `SafetyZoneAreaWidth_${lang}`,
                        filterOutIfKeyIsNotFound: true,
                        mustMatchAllConditions: true,
                        conditions: {
                            items: [
                                {
                                    $type: 'Relewise.Client.Requests.Conditions.LessThanCondition, Relewise.Client',
                                    value: lengthValue,
                                    negated: false,
                                },
                            ],
                        },
                        negated: false,
                    },
                    {
                        $type: 'Relewise.Client.Requests.Filters.ProductDataFilter, Relewise.Client',
                        key: `SafetyZoneAreaLength_${lang}`,
                        filterOutIfKeyIsNotFound: true,
                        mustMatchAllConditions: true,
                        conditions: {
                            items: [
                                {
                                    $type: 'Relewise.Client.Requests.Conditions.LessThanCondition, Relewise.Client',
                                    value: widthValue,
                                    negated: false,
                                },
                            ],
                        },
                        negated: false,
                    },
                ],
                negated: false,
            },
        ],
        negated: false,
    };
}

export async function fetchProducts({
    skip,
    take,
    filters,
    filterCollections,
    sort,
    query,
    lang,
    user,
    currency,
}: {
    skip: number;
    take: number;
    filters: unknown[];
    filterCollections: unknown[];
    sort: unknown;
    query: string;
    lang: string;
    user?: User;
    currency?: string;
}) {
    const { RELEWISE_API_URL, RELEWISE_ENVIRONMENT_ID, RELEWISE_SEARCH_API_KEY } =
        publicRuntimeConfig();
    const hasQuery = query && query.length > 0;
    const relevanceSorting = {
        $type: 'Relewise.Client.DataTypes.Search.Sorting.Product.ProductRelevanceSorting, Relewise.Client',
        order: 'Descending',
        // order: 'Ascending',
        thenBy: null,
    };
    const requestCollection = [];

    const items = [
        ...filterCollections,
        noEmptyDataFieldFilter('PublishStatus', 'product'),
        dataFieldValueContainsFilter({
            key: 'PublishStatus',
            target: 'product',
            matchType: 'Any',
            value: ['published'],
            equals: true,
        }),
    ];

    const personalizedFilter = getPersonalizedFilter(filters as getPersonalizedFilterProps[], lang);
    if (personalizedFilter) items.push(personalizedFilter);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const productRequest: any = {
        $type: 'Relewise.Client.Requests.Search.ProductSearchRequest, Relewise.Client',
        take: take,
        skip: skip,
        facets: {
            items: filters,
        },
        language: {
            value: lang,
        },
        currency: {
            value: currency,
        },
        term: hasQuery ? query : undefined,
        sorting: {
            value: hasQuery ? relevanceSorting : sort,
        },
        filters: {
            items,
        },
        settings: {
            explodedVariants: 1, // Necessary for using facets that target variant data.
            selectedProductProperties: getProductProperties(lang),
            selectedVariantProperties: productVariantProperties,
            includeDisabledProducts: false,
            includeDisabledVariants: false,
        },
        user,
    };

    requestCollection.push(productRequest);

    if (hasQuery) {
        const contentSearchRequest = getRelewiseContentSearchQuery({
            locale: lang,
            take: 100,
            skip: 0,
            term: query,
            filters: [noEmptyDataFieldFilter('Url', 'content')],
            user,
        });
        requestCollection.push(contentSearchRequest);
    }

    const body = {
        settings: {
            language: lang,
            user,
            currency: currency,
        },
        requests: requestCollection,
    };

    return fetcher<SearchRequestCollectionResponseType>(
        `${RELEWISE_API_URL}/${RELEWISE_ENVIRONMENT_ID}/v1/SearchRequestCollection`,
        {},
        { Authorization: `APIKey ${RELEWISE_SEARCH_API_KEY}` },
        'POST',
        JSON.stringify(body),
    ).then((response) => response.json());
}
