import { useRouter } from 'next/router';
import { useProductsStore } from './';
import { productStoreUrlHelper } from '../utils/productStoreUrlHelper';
import { ParsedUrlQueryInput } from 'querystring';
import { isSSR } from '~/shared/utils';

export const isParamsEqual = (paramsA: URLSearchParams, paramsB: URLSearchParams) => {
    const clonedParamA = new URLSearchParams(paramsA);
    const clonedParamB = new URLSearchParams(paramsB);

    return clonedParamA.toString() === clonedParamB.toString();
};

const moveFilterParamsToEnd = (params: URLSearchParams) => {
    const clonedParams = new URLSearchParams(params);
    const filterParamKeys = ['page', 'categories', 'filters'];

    filterParamKeys.map((key) => {
        const paramValue = clonedParams.get(key);
        if (paramValue) {
            clonedParams.delete(key);
            clonedParams.set(key, paramValue);
        }
    });

    return clonedParams;
};

export const useProductsStoreUrlState = () => {
    const {
        pagination,
        selectedFacets,
        selectedCategories,
        setSelectedFacets,
        setSelectedCategories,
        selectedFacetsSort,
    } = useProductsStore();

    const { replace, query, route } = useRouter();

    const { mapFiltersToParams, mapFiltersFromParams } = productStoreUrlHelper();

    const setUrlFromState = () => {
        const params = new URLSearchParams(window.location.search);
        const originalParams = new URLSearchParams(window.location.search);

        const { page } = pagination;

        if (page > 0) {
            params.set('page', `${page + 1}`);
        } else {
            params.delete('page');
        }

        if (selectedCategories.length) {
            params.set('categories', selectedCategories.join(','));
        } else {
            params.delete('categories');
        }

        const filterParam = mapFiltersToParams(selectedFacets);
        if (filterParam) {
            params.set('filters', filterParam);
        } else {
            params.delete('filters');
        }

        if (selectedFacetsSort.sortKey && selectedFacetsSort.sortOrder) {
            params.set('sortKey', selectedFacetsSort.sortKey);
            params.set('sortOrder', selectedFacetsSort.sortOrder);
        } else {
            params.delete('sortKey');
            params.delete('sortOrder');
        }

        // Make sure we have changes before applying a change
        if (!isParamsEqual(params, originalParams)) {
            // Move PLP params to the end of param list to avoid messing with tracking parameters
            const newParams = moveFilterParamsToEnd(params);

            if (isSSR) {
                replace({ pathname: location.pathname, search: newParams.toString() }, undefined, {
                    shallow: true,
                    scroll: false,
                });
            } else {
                // When in browser we don't want to use Router, since it will trigger a re-render.
                window.history.replaceState(
                    {
                        ...window.history.state,
                        url: newParams.toString() ? `${route}?${newParams.toString()}` : route,
                    },
                    '',
                    newParams.toString()
                        ? `${location.pathname}?${newParams.toString()}`
                        : location.pathname,
                );
            }
        }
    };

    const setFacetsFromUrl = () => {
        const urlFacets = mapFiltersFromParams(query);
        if (!urlFacets.length) {
            return;
        }
        setSelectedFacets(urlFacets);
    };

    const setCategoriesFromUrl = () => {
        const categories = query?.categories as string;
        if (!categories) {
            setSelectedCategories([]);
            return;
        }

        setSelectedCategories(categories.split(','));
    };

    const clearUrlState = () => {
        // Make sure we only clear params related to PLP.
        const stateKeys = ['page', 'filter', 'categories'];
        const params = new URLSearchParams(window.location.search);
        stateKeys.map((key) => {
            params.delete(key);
        });
        const query = {} as ParsedUrlQueryInput;
        params.forEach((value, key) => {
            query[key] = value;
        });
        replace(
            {
                pathname: location.pathname,
                //search: params.toString(),
                query,
            },
            undefined,
            {
                shallow: true,
                scroll: false,
            },
        );
    };

    return {
        clearUrlState,
        setUrlFromState,
        setFacetsFromUrl,
        setCategoriesFromUrl,
    };
};
