export type GetRelewiseCategoriesQueryKeyParams = {
    locale: string;
};
export const getRelewiseCategoriesQueryKey = ({ locale }: GetRelewiseCategoriesQueryKeyParams) => {
    return [
        'relewiseCategories',
        {
            locale: locale.toLowerCase(),
        },
    ];
};
