import styled from '@emotion/styled';
import { Text } from '$shared/components/Text';

export const StyledColorDots = styled.ul(({ theme }) => ({
    display: 'flex',
    alignContent: 'center',
    alignItems:'center',
    li: {
        height: theme.spaces[4],
    },
}));

export const StyledColorDot = styled.li<{ color: string }>(({ color, theme }) => ({
    display: 'inline-block',
    backgroundColor: color,
    marginLeft: '-0.4rem',
    width: theme.spaces[4],
    height: theme.spaces[4],
    borderRadius: '100%',
    '&:first-of-type': {
        marginLeft: 0,
    },
}));

export const StyledColorMore = styled(Text)(({ theme }) => ({
    color: theme.colors.grey60,
    marginLeft: '5px',
}));
