import { useMemo } from 'react';
import { KProductCardVariantColor } from '~/lib/data-contract';
import { StyledColorDot, StyledColorDots, StyledColorMore } from './styled';

export type ColorDotsProps = {
    colors: KProductCardVariantColor[];
    maxColors?: number;
};

export const ColorDots = ({ colors, maxColors = 999 }: ColorDotsProps) => {
    const displayColors = useMemo(() => {
        return colors.slice(0, maxColors);
    }, []);
    return (
        <StyledColorDots>
            {displayColors.map((color) => (
                <StyledColorDot color={color.color} title={color.title} key={color.id} />
            ))}
            {colors.length > maxColors && (
                <li>
                    <StyledColorMore as="span" variant="bodySm">
                        +{colors.length - maxColors}
                    </StyledColorMore>
                </li>
            )}
        </StyledColorDots>
    );
};
