import { Product } from '../../model';

export const getProductImage = (product: Product) => {
    const mainVariantImage = product?.allVariants?.find((item) => item?.data?.IsMainVariant?.value)
        ?.data?.ImageCAD1?.value;
    const fallbackVariantImage = product?.allVariants?.find(
        (item) => !!item?.data?.ImageCAD1?.value,
    )?.data?.ImageCAD1?.value;

    return mainVariantImage ?? fallbackVariantImage;
};

export const getProductHoverImage = (product: Product) => {
    if (!product.data?.ProductPhotos) return;

    try {
        const productPhotos = JSON.parse(product.data.ProductPhotos.value);
        return productPhotos?.[0]?.Url as string;
    } catch (e) {
        console.error(e);
    }
    return;
};
