import InfoCircle from '$icons/info-circle.svg';
import { Icon, Image, Tooltip } from '~/shared/components';
import { StyledHoverImage, StyledTooltip } from './styled';
import { useTranslation } from '~/shared/utils';

type HoverImage = {
    hoverImageUrl: string;
    imageQuality?: number;
};

export const HoverImage = ({ hoverImageUrl, imageQuality }: HoverImage) => {
    const { translate } = useTranslation();

    return (
        <StyledHoverImage>
            {
                <>
                    <StyledTooltip>
                        <Tooltip
                            content={translate('Kompan.Commerce.HoverCardImageDisclaimer')}
                            delayDuration={0}
                            contentWidth={370}
                        >
                            <Icon>
                                <InfoCircle />
                            </Icon>
                        </Tooltip>
                    </StyledTooltip>
                    <Image
                        src={hoverImageUrl}
                        objectFit={'cover'}
                        width="400"
                        height="500"
                        layout="fill"
                        quality={imageQuality}
                        disableSkeleton={true}
                    />
                </>
            }
        </StyledHoverImage>
    );
};
