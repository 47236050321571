import { Frame, formatPrice } from '~/shared/utils';
import { Product } from '../../model';

export const getPriceText = (product: Product, frame: Frame, locale: string) => {
    if (!product?.listPrice || !frame?.currencySymbol) return;
    if (product?.data?.ShowPriceOnKompan?.value == 'False') {
        return;
    }
    const price = formatPrice({
        amount: `${product?.listPrice}`,
        currencyCode: frame?.currency,
        locale,
        options: {
            minimumFractionDigits: 0,
        },
    });
    // return `${frame?.currencySymbol} ${price}`;
    return `${price} ${frame?.currency} `;
};
