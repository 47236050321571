export interface FormatPriceOptions {
    amount?: string | null;
    currencyCode?: string | null;
    locale?: string;
    options?: Intl.NumberFormatOptions;
}
export const formatPrice = ({
    amount,
    currencyCode,
    locale = 'da-DK',
    options = {},
}: FormatPriceOptions): string => {
    if (!amount || !currencyCode) return '';
    const resolvedLocale = locale.toLowerCase() === 'en-int' ? 'da-DK' : locale;
    return new Intl.NumberFormat(resolvedLocale, {
        style: 'currency',
        currency: currencyCode,
        ...options,
    })
        .formatToParts(parseFloat(amount.replace(',', '.')))
        .map((p) => (p.type != 'literal' && p.type != 'currency' ? p.value : ''))
        .join('');
};
