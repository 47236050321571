import { create } from 'zustand';

export type SearchState = {
    query: string;
    setQuery: (query: string) => void;
    searchInputValue: string;
    setSearchInputValue: (value: string) => void;
    isOpen: boolean;
    setIsOpen: (open: boolean) => void;
    dropdownRef?: HTMLElement | null;
    setDropdownRef: (ref: HTMLElement | null) => void;
    isFocused: boolean;
    setIsFocused: (isFocused: boolean) => void;
};

export const useSearchState = create<SearchState>((set) => ({
    query: '',
    setQuery: (query: string) => {
        set(() => ({
            query,
        }));
    },
    searchInputValue: '',
    setSearchInputValue: (value: string) => {
        set(() => ({
            searchInputValue: value,
        }));
    },
    isOpen: false,
    setIsOpen: (open: boolean) => {
        set(() => ({
            isOpen: open,
        }));
    },
    dropdownRef: null,
    setDropdownRef: (ref: HTMLElement | null) => {
        set(() => ({
            dropdownRef: ref,
        }));
    },
    isFocused: false,
    setIsFocused: (isFocused: boolean) => {
        set(() => ({
            isFocused,
        }));
    },
}));
