import { SelectedFacet } from '../../../model';
import { FacetsBodyType } from '../model';

export type GetRelewiseProductQueryKeyParams = {
    locale: string;
    page: number;
    perPage: number;
    query?: string;
    selectedFacets: SelectedFacet[];
    facetKey: string;
    activeCategories?: string[];
    lockedFacets: FacetsBodyType[];
    sortKey?: string;
};
export const getRelewiseProductQueryKey = ({
    locale,
    page,
    perPage,
    query,
    selectedFacets,
    facetKey,
    activeCategories = [],
    lockedFacets,
    sortKey,
}: GetRelewiseProductQueryKeyParams) => {
    return [
        'relewiseProducts',
        {
            locale: locale.toLowerCase(),
            page: page,
            perPage: perPage,
            query: query,
            selectedFacets: selectedFacets,
            facetKey: facetKey,
            activeCategories: activeCategories.sort(),
            lockedFacets: lockedFacets,
            sortKey: sortKey,
        },
    ];
};
