import { KProductCardVariantColor } from '~/lib/data-contract';
import { Product, ProductVariant } from '../../model';

export const getProductVariantColors = (product: Product) => {
    const colors: KProductCardVariantColor[] =
        (product.allVariants as ProductVariant[])?.reduce((collection, variant: ProductVariant) => {
            const title = variant.data?.MainColor?.value;
            const color = variant.data?.MainColorHexCode?.value;
            if (!title || !color) return collection;
            return [
                ...collection,
                {
                    id: variant?.variantId,
                    title,
                    color,
                },
            ];
        }, [] as KProductCardVariantColor[]) || [];
    return colors;
};
