import type { MarketSelectorMarket } from '~/lib/data-contract';
import { useFrame } from '~/shared/utils';
import { usePage } from '../hooks/usePage';

export const getCurrentMarket = (): MarketSelectorMarket | undefined => {
    const { data: frameData } = useFrame();
    const { market: pageMarket, culture: pageCulture } = usePage();
    const marketKey = `${pageCulture?.toLocaleLowerCase()}-${pageMarket?.toLocaleLowerCase()}`;

    // Find the first market that includes the current language and market combination.
    const currentMarket = frameData?.marketSelector?.markets?.find((market) => {
        return market?.languages?.find((lang) => {
            return lang.culture?.toLowerCase() == marketKey;
        });
    });

    return currentMarket ? currentMarket : undefined;
};

/**
 *
 * @returns Path for current market without starting or trailing slash. i.e: 'en/int'
 */
export const getCurrentMarketPath = (): string => {
    const { market, culture } = usePage();
    return market ? `${culture}/${market.toLowerCase()}` : `${culture}`;
};

export const getCurrentLocale = (): string => {
    const { market, culture } = usePage();
    return market ? `${culture}-${market.toUpperCase()}` : `${culture}`;
};
