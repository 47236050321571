import { SortFacetsType, SortType } from "../../../model";

export const getSort = (selectedFacetsSort: SortFacetsType,  selectedSort: SortType | undefined ) => {
    let sort = undefined;

    if (selectedFacetsSort.sortKey && selectedFacetsSort.sortOrder) {          

        if (selectedFacetsSort.sortKey === 'DisplayName' || selectedFacetsSort.sortKey === 'listPrice') {
            sort = {
                $type: 'Relewise.Client.DataTypes.Search.Sorting.Product.ProductAttributeSorting, Relewise.Client',
                attribute: selectedFacetsSort.sortKey,
                Mode: 'Auto', // Auto, Alphabetical, Numerical
                Order: selectedFacetsSort.sortOrder === 'asc' ? 'Ascending' : 'Descending',
            };
        }else{
            sort = {
                $type: 'Relewise.Client.DataTypes.Search.Sorting.Product.ProductDataSorting, Relewise.Client',
                key: selectedFacetsSort.sortKey,
                Mode: 'Auto', // Auto, Alphabetical, Numerical
                Order: selectedFacetsSort.sortOrder === 'asc' ? 'Ascending' : 'Descending',
            };
        }        

        
    }

    if (selectedSort?.attribute !== undefined) {
        sort = {
            $type: 'Relewise.Client.DataTypes.Search.Sorting.Product.ProductAttributeSorting, Relewise.Client',
            Attribute: selectedSort.attribute,
            Mode: 'Auto',
            Order: selectedSort.sort === 'asc' ? 'Ascending' : 'Descending',
        };
    }
    return sort;
};