import fallbackImage from '$assets/fallback-product-card.svg';
import UserLight from '$icons/user-light.svg';
import Size from '$icons/size.svg';
import UsersLight from '$icons/users-light.svg';
import { CallToAction, Icon, Text, Tooltip } from '$shared/components';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { ReactNode, memo, useCallback, useEffect, useState } from 'react';
import { useSwiperSlide } from 'swiper/react';
import { KProductCardModel, KProductCardType, Link as LinkType } from '~/lib/data-contract';
import { queries, theme } from '~/theme';
import { ColorDots } from '../ColorDots/ColorDots';
import { Image } from '../Image';
import {
    StyledCTAWrapper,
    StyledCardInner,
    StyledCardLink,
    StyledCardPrice,
    StyledCardTitle,
    StyledCardWrapper,
    StyledContentWrapper,
    StyledImageWrapper,
    StyledMetaWrapper,
    StyledProductDetail,
    StyledProductDetails,
    StyledProductDetailsWrapper,
    StyledSpacer,
} from './styled';
import InfoCircle from '$icons/info-circle-round.svg';
import { useTranslation } from '~/shared/utils';
import { HoverImage } from './HoverImage';
import { useIsDesktop } from '~/shared/hooks/useIsDesktop/useIsDesktop';

const getTooltip = (text: string) => {
    if (!text) return;
    return (
        <Tooltip content={text} delayDuration={0} contentWidth={370}>
            <Icon>
                <InfoCircle />
            </Icon>
        </Tooltip>
    );
};

export const _KProductCard = ({
    type = KProductCardType.REGULAR,
    title,
    buttonText,
    price,
    productId,
    tags,
    colors,
    imageUrl,
    url,
    ageGroup,
    userCapacity,
    spaceRequired,
    onNavigate,
    hoverImageUrl,
    hoverImageQuality,
    smallCard, // TODO use a new type instead
    children,
    ...rest
}: KProductCardModel & React.HTMLAttributes<HTMLDivElement>): JSX.Element => {
    const colorsToShow = 5;
    const router = useRouter();
    const { translate } = useTranslation();

    const getCallToAction = () => {
        return {
            url,
            text: buttonText,
            title: buttonText,
            iconName: 'arrow-right',
            shade: 'light',
            variation: 'Transparent',
        } as LinkType;
    };
    const swiperSlide = useSwiperSlide();

    const [isHovered, setIsHovered] = useState(false);
    const isDesktop = useIsDesktop();

    const handleMouseEnter = useCallback(() => {
        isDesktop && hoverImageUrl && setIsHovered(true);
    }, [hoverImageUrl, isDesktop]);

    const handleMouseLeave = useCallback(() => {
        isDesktop && hoverImageUrl && setIsHovered(false);
    }, [hoverImageUrl, isDesktop]);

    useEffect(() => {
        const onRouteChange = (newUrl: string) => {
            if (newUrl == url && onNavigate) {
                onNavigate();
            }
        };
        router.events.on('routeChangeStart', onRouteChange);
        return () => {
            router.events.off('routeChangeStart', onRouteChange);
        };
    }, [router.events]);

    return (
        <StyledCardWrapper
            productType={type}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            smallCard={smallCard}
            {...rest}
        >
            <NextLink href={url as string} prefetch={false} legacyBehavior>
                <StyledCardInner productType={type} smallCard={smallCard}>
                    <ConditionalLink type={type} url={url} smallCard={smallCard}>
                        <StyledImageWrapper productType={type}>
                            <Image
                                alt={title}
                                src={imageUrl ? imageUrl : fallbackImage}
                                objectFit={'contain'}
                                layout="responsive"
                                quality="70"
                                width="400"
                                height="300"
                                sizes={`${queries.xs} 50vw, ${queries.contentMax} 375px, ${queries.md} 33vw, ${queries.sm} 50vw,`}
                                disableSkeleton={true}
                                // Preload images if on next slide. Only works if 1 image is shown.
                                priority={swiperSlide?.isNext || swiperSlide?.isPrev}
                            />
                            {!smallCard &&
                                !hoverImageUrl &&
                                hoverImageUrl &&
                                isDesktop &&
                                isHovered && (
                                    <HoverImage
                                        imageQuality={hoverImageQuality}
                                        hoverImageUrl={hoverImageUrl}
                                    />
                                )}
                        </StyledImageWrapper>
                        <StyledContentWrapper productType={type}>
                            {type === KProductCardType.REGULAR && (
                                <StyledMetaWrapper>
                                    {productId && (
                                        <Text
                                            variant="bodySm"
                                            style={{ lineHeight: 1.4, color: theme.colors.grey60 }}
                                        >
                                            {productId}
                                        </Text>
                                    )}
                                    <StyledSpacer />
                                    {tags &&
                                        tags.map((tag, index) => (
                                            <Text
                                                variant="bodySm"
                                                style={{
                                                    lineHeight: 1.4,
                                                    color: theme.colors.grey60,
                                                }}
                                                key={index}
                                            >
                                                {tag}
                                            </Text>
                                        ))}
                                </StyledMetaWrapper>
                            )}
                            {title && (
                                <StyledCardTitle as="h2" variant={'display5'} smallCard={smallCard}>
                                    {title}
                                </StyledCardTitle>
                            )}
                            {type === KProductCardType.REGULAR && price && (
                                <StyledCardPrice>
                                    {price}{' '}
                                    {getTooltip(translate('Kompan.ProductDetail.PriceDisclaimer'))}
                                </StyledCardPrice>
                            )}

                            {type === KProductCardType.REGULAR && !smallCard && (
                                <StyledProductDetailsWrapper>
                                    <StyledProductDetails>
                                        {ageGroup && (
                                            <StyledProductDetail>
                                                <Icon size="md">
                                                    <UserLight />
                                                </Icon>
                                                <Text
                                                    variant="bodySm"
                                                    style={{
                                                        color: theme.colors.grey60,
                                                    }}
                                                >
                                                    {ageGroup}
                                                </Text>
                                            </StyledProductDetail>
                                        )}

                                        {spaceRequired && (
                                            <StyledProductDetail>
                                                <Icon size="md">
                                                    <Size />
                                                </Icon>
                                                <Text
                                                    variant="bodySm"
                                                    style={{
                                                        color: theme.colors.grey60,
                                                    }}
                                                >
                                                    {spaceRequired}
                                                </Text>
                                            </StyledProductDetail>
                                        )}
                                        {userCapacity && (
                                            <StyledProductDetail>
                                                <Icon size="md">
                                                    <UsersLight />
                                                </Icon>
                                                <Text
                                                    variant="bodySm"
                                                    style={{
                                                        color: theme.colors.grey60,
                                                    }}
                                                >
                                                    {userCapacity}
                                                </Text>
                                            </StyledProductDetail>
                                        )}
                                    </StyledProductDetails>
                                    {!!colors?.length && (
                                        <ColorDots colors={colors} maxColors={colorsToShow} />
                                    )}
                                </StyledProductDetailsWrapper>
                            )}
                            {buttonText && url && type !== KProductCardType.REGULAR && (
                                <StyledCTAWrapper>
                                    <CallToAction callToAction={getCallToAction()} />
                                </StyledCTAWrapper>
                            )}
                        </StyledContentWrapper>
                    </ConditionalLink>
                </StyledCardInner>
            </NextLink>
            {children}
        </StyledCardWrapper>
    );
};

const ConditionalLink = memo(
    ({
        children,
        url,
        type,
        smallCard,
    }: {
        children: ReactNode | ReactNode[];
        url?: string;
        type: KProductCardType;
        smallCard?: boolean;
    }) => {
        if (!url || type != KProductCardType.REGULAR) {
            return <>{children}</>;
        }
        return (
            <StyledCardLink smallCard={smallCard} href={url}>
                {children}
            </StyledCardLink>
        );
    },
);

export const KProductCard = memo(_KProductCard);
KProductCard.displayName = 'KProductCard';
